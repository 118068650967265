const theme = {
  breakpoints: ['599px', '899px', '1199px', '1499px'],
  fontSizes: ['0.95rem', '1.2rem', '1.44rem', '1.728rem', '2.074rem', '2.488rem'],
  colors: {
    primary:'#46AB9A',
    gray:'#696767',
    sanddark:'#D8D5BD',
    sandlight:'#F4F1D3',
    white:'#fff',
    black:'#000',
  },
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  sidebarWidth: {
    big: '375px',
    normal: '320px',
  },
}

export default theme