
import React from 'react'
/*import PropTypes from 'prop-types'*/
import {ThemeProvider} from 'styled-components'
import theme from '../config/theme'
import Header from '../components/header'
import Footer from '../components/footer'
import "@fontsource/black-han-sans"
import "@fontsource/hind-siliguri"
import CookieConsent from 'react-cookie-consent';
/*import styles from './layout.module.scss'*/


const Layout = ({ children }) => {
 
  return (
    <ThemeProvider theme={theme}>  
      <div className="page">   
        <Header/>
        <main className="content" >
          {children} 
        </main> 
        <Footer/> 
        <CookieConsent
          enableDeclineButton 
          flipButtons
          style={{background:'#161616e3', fontSize:"1.4rem"}}
          buttonStyle={{ color: "#fff", borderRadius:"4px", background: "#3ba997" }}
          declineButtonStyle={{ color: "#fff", borderRadius:"4px", background: "#696767" }}
          location="bottom"
          buttonText="Accepter"
          declineButtonText="Refuser"
          cookieName="gatsby-gdpr-google-analytics">
        Le site iaross.com utilise un cookie de performance et d’analyse, de manière anonyme. Il permet uniquement d’améliorer notre site. Non utilisé à des fins de publicité ou par des tiers.
        </CookieConsent> 
      </div>
    </ThemeProvider>

  )
}

export default Layout