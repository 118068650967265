import React from "react"


import { Icon } from '@iconify/react'
import spotifyIcon from '@iconify/icons-mdi/spotify'
import instagramIcon from '@iconify/icons-mdi/instagram'
import facebookIcon from '@iconify/icons-mdi/facebook'
import youtubeIcon from '@iconify/icons-mdi/youtube'

const data = [
  
  {
    id: 1,
    icon:  <Icon icon={facebookIcon} className='social-icon' style={{color: '#46ab9a', fontSize: '36px'}} />,
    url: "https://www.facebook.com/iAROSSgroupe/",
    title: "facebook",
  },
  {
    id: 2,
    icon:  <Icon icon={instagramIcon} className='social-icon'style={{color: '#46ab9a', fontSize: '36px'}} />,
    url: "https://www.instagram.com/iaross_officiel/",
    title: "instagram",
  },
  {
    id: 3,
    icon: <Icon icon={spotifyIcon} className='social-icon'style={{color: '#46ab9a', fontSize: '36px'}} />,
    url: "https://open.spotify.com/artist/4fRdB6dkmgUY3WmFrKLtnq",
    title: "spotify",
  },
  {
    id: 4,
    icon:  <Icon icon={youtubeIcon} className='social-icon' style={{color: '#46ab9a', fontSize: '36px'}} />,
    url: "https://www.youtube.com/user/Iarooossss",
    title: "youtube",
  },
]

export default data