import React from 'react'
import { Link } from 'gatsby'
import socialLinks from "../config/socialLinks"
import Boop from "./boop"

const Footer = () => {

  return (
    <footer className="footer">
      <div className="container">
        <div className="social-links">
          {socialLinks.map(link => {
            return (
            <Boop scale={1.05} key={link.id}  timing={200} >
              <a href={link.url} className="social-link" title={link.title} target="_blank" rel="noopener noreferrer">
                        {link.icon}
              </a>
            </Boop>  
            )
          })}
        </div>
        <p className="copyright">© iAROSS - {new Date().getFullYear()} - <Link to ="/mentions-legales" className="link-effect">Mentions légales</Link>{' - '}<Link to ="/credits" className="link-effect">Crédits</Link></p>
        
      </div>
    </footer> 
  )
}

export default Footer