import React, {useState} from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {useSpring, animated } from 'react-spring'
import { StaticImage } from 'gatsby-plugin-image'
import Headroom from 'react-headroom'
import { HamburgerSliderReverse } from 'react-animated-burgers'
import './headroom.scss'
import socialLinks from "../config/socialLinks"
import Boop from "./boop"

/*MENU MOBILE*/
const MenuMobile = styled(animated.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index:9;
  transform: translate3d(0,-100%,0);

`
const ContentMobile = styled(animated.div)`
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  nav {
    color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    a {
      color:white;
        position: relative;
        font-size:3.6rem;
        letter-spacing: -.01rem;
        font-weight:300;
        margin:0.8rem;
        text-decoration:none;
        text-align: center;
    }
  
  }
`

const Header = () => {
  
 
  const [mobileNavOpen, setMobileNavOpen] = useState(false) // closed by default
  const MenuAnimation = useSpring({
  native: true,
  to: { opacity: mobileNavOpen ? 1 : 0, transform: mobileNavOpen ? 'translate3d(0,0,0)' : 'translate3d(0,-100%, 0)', backgroundColor: mobileNavOpen ? '#46ab9a' : '#fff'},
  })


  return (
    <>
      <MenuMobile style={ MenuAnimation}> 
        <ContentMobile className="link-header">
          <nav className="navigation">
            <Link to="/dates" activeClassName="active"  onClick={() => {setMobileNavOpen(!mobileNavOpen)}} className="link-effect" >Dates</Link>
            <Link to="/biographie" activeClassName="active"  onClick={() => {setMobileNavOpen(!mobileNavOpen)}} className="link-effect" >Biographie</Link>
            <Link to="/videos" activeClassName="active" onClick={() => {setMobileNavOpen(!mobileNavOpen)}} className="link-effect" >Vidéos</Link>
            <Link to="/discographie" activeClassName="active"  onClick={() => {setMobileNavOpen(!mobileNavOpen)}} className="link-effect" >Discographie</Link>
            <Link to="/contact" activeClassName="active" onClick={() => {setMobileNavOpen(!mobileNavOpen)}} className="link-effect" >Contact </Link>
            
          </nav>
         {/* <Img fluid={logo.file.childImageSharp.fluid} className={styles.menuMobileBackground} />*/}
         <div id="social-links-menu">
              {socialLinks.map(link => {
                return (
                
                <Boop scale={1.05} key={link.id}  timing={200} >
                  <a href={link.url} className="social-link" title={link.title} target="_blank" rel="noopener noreferrer">
                            {link.icon}
                  </a>
                </Boop>  
                )
              })}
            </div>
        </ContentMobile>     
      
      </MenuMobile>    
     
      <Headroom calcHeightOnResize disableInlineStyles>
       <header className="header container">
        {/* <Fade down duration={100}> */}
            <div className="branding">
              <Link to="/" title="Retour à l'accueil">    
                <StaticImage
                  src="../assets/images/logo-iaross-382.png"
                  alt="Iaross"
                  placeholder="blurred"
                  layout="fixed"
                  width={145}
                  height={95}
                  className="logo" 
                />
              </Link>
            </div>
            <div className="menu"> 
              <nav className="navigationDesktop">
                <Link to="/dates" activeClassName="active"  className='link-effect'>Dates</Link>
                <Link to="/biographie"  activeClassName="active"  className='link-effect'>Biographie</Link>
                <Link to="/videos" activeClassName="active"  className='link-effect'>Videos</Link>
                <Link to="/discographie" activeClassName="active" className='link-effect'>Discographie</Link>
                <Link to="/contact" activeClassName="active" className='link-effect'>Contact</Link>
              </nav> 
            </div>
            <div className="social-links">
              {socialLinks.map(link => {
                return (
                
                <Boop scale={1.05} key={link.id}  timing={200} >
                  <a href={link.url} className="social-link" title={link.title} target="_blank" rel="noopener noreferrer">
                            {link.icon}
                  </a>
                </Boop>  
                )
              })}
            </div>
            <div className="menuMobileTrigger"> 
            <Boop scale={1.01} timing={200} >
               <HamburgerSliderReverse isActive={mobileNavOpen} toggleButton={() => {setMobileNavOpen(!mobileNavOpen)}} buttonStyle={{ outline: "none!important" }} barColor="#000"  aria-label="Navigation"/>
              </Boop>
          </div>
        {/* </Fade> */}
        </header>
      </Headroom>
    </>

    
  )
}

export default Header

 
